import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import FeedbackApi from '../../api/feedbacks';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getFeedbacks($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_FEEDBACK_FETCHING_STATUS, true);
    try {
      const response = await FeedbackApi.get();
      commit(types.default.SET_FEEDBACK_FETCHING_STATUS, false);
      commit(types.default.SET_FEEDBACK, response.data.payload);
      return response?.data?.payload;
    } catch (error) {
      commit(types.default.SET_FEEDBACK_FETCHING_STATUS, false);
    }
  },
  create: async ({ commit }, feedbackInfo) => {
    commit(types.default.SET_FEEDBACK_CREATING_STATUS, true);
    try {
      const response = await FeedbackApi.create(feedbackInfo);
      commit(types.default.ADD_FEEDBACK, response.data);
      commit(types.default.SET_FEEDBACK_CREATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_FEEDBACK_CREATING_STATUS, false);
      throw error;
    }
  },
  update: async ({ commit }, { id, ...feedbackParams }) => {
    commit(types.default.SET_FEEDBACK_UPDATING_STATUS, true);
    try {
      const response = await FeedbackApi.update(id, feedbackParams);
      commit(types.default.EDIT_FEEDBACK, response.data);
      commit(types.default.SET_FEEDBACK_UPDATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_FEEDBACK_UPDATING_STATUS, false);
      throw new Error(error);
    }
  },
  delete: async ({ commit }, feedbackId) => {
    commit(types.default.SET_FEEDBACK_DELETING_STATUS, true);
    try {
      await FeedbackApi.delete(feedbackId);
      commit(types.default.DELETE_FEEDBACK, feedbackId);
      commit(types.default.SET_FEEDBACK_DELETING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_FEEDBACK_DELETING_STATUS, false);
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_FEEDBACK_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_FEEDBACK_CREATING_STATUS]($state, status) {
    $state.uiFlags.isCreating = status;
  },
  [types.default.SET_FEEDBACK_UPDATING_STATUS]($state, status) {
    $state.uiFlags.isUpdating = status;
  },
  [types.default.SET_FEEDBACK_DELETING_STATUS]($state, status) {
    $state.uiFlags.isDeleting = status;
  },

  [types.default.SET_FEEDBACK]: MutationHelpers.set,
  [types.default.ADD_FEEDBACK]: MutationHelpers.create,
  [types.default.EDIT_FEEDBACK]: MutationHelpers.update,
  [types.default.DELETE_FEEDBACK]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
