/* global axios */

import ApiClient from './ApiClient';

class CannedResponse extends ApiClient {
  constructor() {
    super('canned_responses', { accountScoped: true });
  }

  get({ searchKey, inboxId }) {
    const url = searchKey
      ? inboxId
        ? `${this.url}?search=${searchKey}&inbox_id=${inboxId}`
        : `${this.url}?search=${searchKey}`
      : inboxId
      ? `${this.url}?inbox_id=${inboxId}`
      : this.url;
    return axios.get(url);
  }
}

export default new CannedResponse();
