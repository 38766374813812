import SettingsContent from '../Wrapper.vue';
import Index from './Index.vue';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/feedbacks'),
      component: SettingsContent,
      props: {
        headerTitle: 'FEEDBACK_MESSAGE.HEADER',
        icon: 'code',
        showNewButton: true,
      },
      children: [
        {
          path: '',
          name: 'feedback_list',
          component: Index,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
