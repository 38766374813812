import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import IframeCreatorApi from '../../api/iframeCreator';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getIframeCreators($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_IFRAME_CREATOR_FETCHING_STATUS, true);
    try {
      const response = await IframeCreatorApi.get();
      commit(types.default.SET_IFRAME_CREATOR_FETCHING_STATUS, false);
      commit(types.default.SET_IFRAME_CREATOR, response.data.payload);
    } catch (error) {
      commit(types.default.SET_IFRAME_CREATOR_FETCHING_STATUS, false);
    }
  },
  create: async ({ commit }, iframeInfo) => {
    commit(types.default.SET_IFRAME_CREATOR_CREATING_STATUS, true);
    try {
      const response = await IframeCreatorApi.create(iframeInfo);
      commit(types.default.ADD_IFRAME_CREATOR, response.data);
      commit(types.default.SET_IFRAME_CREATOR_CREATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_IFRAME_CREATOR_CREATING_STATUS, false);
      throw error;
    }
  },
  update: async ({ commit }, { id, ...iframeCreatorParams }) => {
    commit(types.default.SET_IFRAME_CREATOR_UPDATING_STATUS, true);
    try {
      const response = await IframeCreatorApi.update(id, iframeCreatorParams);
      commit(types.default.EDIT_IFRAME_CREATOR, response.data);
      commit(types.default.SET_IFRAME_CREATOR_UPDATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_IFRAME_CREATOR_UPDATING_STATUS, false);
      throw new Error(error);
    }
  },
  delete: async ({ commit }, iframeCreatorId) => {
    commit(types.default.SET_IFRAME_CREATOR_DELETING_STATUS, true);
    try {
      await IframeCreatorApi.delete(iframeCreatorId);
      commit(types.default.DELETE_IFRAME_CREATOR, iframeCreatorId);
      commit(types.default.SET_IFRAME_CREATOR_DELETING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_IFRAME_CREATOR_DELETING_STATUS, false);
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_IFRAME_CREATOR_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_IFRAME_CREATOR_CREATING_STATUS]($state, status) {
    $state.uiFlags.isCreating = status;
  },
  [types.default.SET_IFRAME_CREATOR_UPDATING_STATUS]($state, status) {
    $state.uiFlags.isUpdating = status;
  },
  [types.default.SET_IFRAME_CREATOR_DELETING_STATUS]($state, status) {
    $state.uiFlags.isDeleting = status;
  },

  [types.default.SET_IFRAME_CREATOR]: MutationHelpers.set,
  [types.default.ADD_IFRAME_CREATOR]: MutationHelpers.create,
  [types.default.EDIT_IFRAME_CREATOR]: MutationHelpers.update,
  [types.default.DELETE_IFRAME_CREATOR]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
